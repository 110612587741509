.image-gallery-thumbnail img {
  width: auto;
  /* height: 75px; */
  border-radius: 10px;
  object-fit: cover !important;
  height: 70px;
  width: 60px;
}
.image-gallery-thumbnail {
  border-radius: 15px;
  /* object-fit: cover !important; */
  /* height: 20px; */
  /* width: 60px; */
}

.image-gallery {
  width: 100%;
  object-fit: contain !important;
  /* height: auto; */
}

.image-gallery-slide .image-gallery-image {
  width: 100%;
  height: 75vw;
  /* max-height: 80vh; */
  object-fit: cover !important;
  /* overflow: hidden; */
  /* object-position: center center; */
}

.fullscreen .image-gallery-slide img {
  /* max-height: 100vh !important; */
  min-height: 50 vh !important;
  object-fit: contain !important;
}

.image-gallery-thumbnails {
  /* position: absolute; */
  bottom: 0;
}

.image-gallery-thumbnails-wrapper {
  position: relative;
  bottom: 0;
}

.image-gallery-content {
  height: 100%;
  object-fit: contain !important;
}

.image-gallery-slide-wrapper {
  top: 25%;
  object-fit: contain !important;
}

.image-gallery-fullscreen-button {
  position: absolute;
  top: 100%;
  object-fit: contain !important;
}

.image-gallery-thumbnails {
  margin-top: 10px;
}
/* .fs-gallery-normal .image-gallery-slide img {
  width: 100%;
  object-position: center !important;
  height: 380px;
  border-radius: 10px;
  cursor: pointer;
} */
